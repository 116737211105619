import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export const Config = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        json: contentfulTable(title: { eq: "config" }) {
          childContentfulTableTableJsonNode {
            internal {
              content
            }
          }
        }
      }
    `}
  >
    {({
      json: {
        childContentfulTableTableJsonNode: {
          internal: { content },
        },
      },
    }) => children(JSON.parse(content))}
  </StaticQuery>
);
