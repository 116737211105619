// import { config } from '../../config';

const docs = (mediaKit, config) => [
  {
    id: 0,
    title: 'Overview',
    body: `For more than 120 years, The Australian Jewish News has been the voice of Australia’s Jewish community.
    With two editions each week - Sydney and Melbourne - The AJN covers local, federal and international news, opinion, arts, lifestyle and sport.
    Advertisers looking for a high net - worth niche market are attracted to advertising in The AJN.
    Our readership is very well represented in business, the professions, academia and the arts.
    Singapore Airlines, Commonwealth Bank, Bank of Queensland, Coles and Woolworths are all regular advertisers.`,
    keywords: ['About', 'info', 'Overview'],
    to: '/overview',
  },
  {
    id: 1,
    title: 'Readership & Reach',
    body: `Print edition: 192,000 readership per month
    jewishnews.net.au: 120,000+ page views per month
    iPad and e-paper: 3,500 subscribers per month
    Facebook and Twitter: Up to 100,000 organic reach per post
    70% penetration into Jewish households.
    Frequency and distribution
    Two editions per week (one Sydney, one Melbourne)
    Delivered and sold through over 180 newsagents throughout the country and mailed to subscribers each Thursday.
    51 issues per year each state
    $3.80 cover price`,
    keywords: [
      'Readership',
      'stats',
      'statistics',
      'distribution',
      'numbers',
      'coverage',
      'reach',
      "Australia's Jewish community",
      'data',
      'graph',
      'education',
    ],
    to: '/readership',
  },
  {
    id: 2,
    title: 'Print',
    body: `Our coverage
Australia’s leading community newspaper
News

Award-winning local, state and federal news concerning Australia’s Jewish community.

Prime position
COVER STRIP, INSIDE FRONT, PAGES 3, 5, 7, 9.
World

International news, featuring on-theground reports by our foreign correspondent in Israel.

Prime position
WORLD LEAD
Comment

International news, featuring on-theground reports by our foreign correspondent in Israel.

Arts

The who’s who of the Jewish arts world. Film, theatre, literature and music are all covered.

Prime position
ARTS LEAD
Lifestyle

Each week we focus on a different topic: Home, Food, Health and Beauty, Travel and Tech.

Prime position
Editorial opportunities available to all advertisers
Sports

Match reports, featured interviews and extensive coverage of local and world sport.

Prime position
BACK PAGE
In Focus

Our weekly pictorial celebration of Australia’s thriving Jewish community.

Prime position
IN FOCUS COVER, BEEN & SEEN, WHAT’S ON
Supplements and magazines
Editorial opportunities available to all advertisers in our magazines and supplements
Wedding

Preview latest edition open_in_new
Our premium annual magazine featuring real weddings, fashion, photography, venues, music and catering.

Bar/Bat Mitzvah

Preview latest edition open_in_new
Australia’s only guide to hosting a Bar or Bat Mitzvah celebration and the diverse Bar and Bat Mitzvah program offerings and opportunites.

Kosher Living

Preview latest edition open_in_new
Latest products and recipes for the Kosher lifestyle.

Education for life

Preview latest edition open_in_new
From kindergarten to University, short courses and trends in education. For all ages and stages we are a community of life-long learners.

Festival magazines

Our anchor magazines tied in with major jewish festivals. Including Rosh Hashanah (Jewish New Year), Pesach (Passover) and Chanukah.

A Healthy Lifestyle

The AJN produces quarterly health supplements with the largest junior sporting club in Australia.

Inserts and wraps

Gloss wraps and inserts available. Get your publication out to our readers.`,
    keywords: ['print', 'print advertising', 'coverage', 'supplements', 'magazines'],
    to: '/print',
  },
  {
    id: 3,
    title: 'Print Rates & Sizes',
    body: `Print Rates & Sizes
  Prices per state - discount applies for national advertising
  Full Page
  26.2cm wide x 37cm high

  $3,090
  Half Page
  Horizontal: 26.2cm wide x 18.3cm high

  Vertical: 13cm wide x 37cm high

  $1,760
  Quarter page
  Horizontal: 26.2cm wide x 9cm high

  Vertical: 13cm wide x 18.3cm high

  $1,012
  Eighth page
  13cm wide x 9cm high

  $552
  Module
  6.3cm wide x 9cm high

  $276
  Front CoverStrip
  26.2cm wide x 5cm high

  $1,575
  PRIME POSITIONS
  Position	loading
  Early General News (pages 3, 5, 7 & 9)	+35%
  Back Page (Sport)	+35%
  In focus Cover	+25%
  world Lead	+15%
  Arts Lead	+15%
  Been & Seen	+15%
  What’s on	+15%
  All prices subject to 10% GST.
  multiple bookings

  3 to 6: 5% discount 
  7 to 12: 7.5% discount 
  13+: 10% discount 

  inserts and wraps
  Contact your AJN advertising representative for further details. `,
    keywords: [''],
    to: '/print/rates-sizes',
  },
  {
    id: 4,
    title: 'Print Specs',
    body: `Print Specs
AJN newspaper and newspaper style supplements
Fonts & Type
Minimum font sizes

Type Minimum point size
Solid type using one process colour	7pt
Coloured type on a white background using two process colours. 9pt Bold
Coloured type on a white background using three process colours	12pt bold
Reversed (white) type out of one process colour	9pt
Reversed (white) type out of two or more process colours (San serif font recommended)	12pt Bold
Black type should be made from 100% black only to ensure there are no registration problems.

Accepted media
Email (up to 6MB) or file transfers systems eg. Dropbox, Wetransfer, You send it, etc. for files over 6MB

CD Rom

USB flash memory devices

ALL DISCS MUST BE MACINTOSH COMPATIBLE

Preferred format
PDF (fonts embedded)

We accept high-resolution pdf files, distilled using “Press Quality” job options in Acrobat Distiller.

We only accept pdf files distilled from original InDesign, Illustrator, Quark Xpress or CorelDraw documents.

When setting your PDF preferences, please use the PRESS QUALITY setting to ensure a high resolution file.

Please ensure that all colour is CMYK.

We do not accept pdf files distilled from photoshop, publisher or word documents

Sizing
When preparing your advertisement in your native program, please ensure that your paper size and your advertisement size are the same.Registration and trim marks are not required.

Artwork with registration and trim marks will not be accepted.

Colour
All colour must be converted to CMYK (not RGB, spot, Pantone, etc.).

Files with RGB or spot colour will not be accepted.

In InDesign and Illustrator preferences please ensure that under “Appearance of Black” the ‘Printing / Exporting’ option is set to “Output all blacks accurately”.

Graphics or Images
We support Illustrator and Photoshop files in EPS, TIFF and JPG formats. Images should be 200dpi (100lpi) at 100%.

All logos should be supplied as either single bitmap or vector artwork.

Dot gain
Please allow for up to 30% dot gain in Photoshop printing inks setup.

Download our colour setting (.csf) file here.

Maximum ink weight
The total ink weight used should not exceed 230%.

If this is exceeded, it can hinder drying, trimming, folding and could scuff or set off.

See our website to download ink weight instructions.`,
    keywords: [
      'specs',
      'specifications',
      'color',
      'colour',
      'cmyk',
      'dot gain',
      'csf',
      'pdf',
      'indesign',
      'illustrator',
      'format',
      'design',
      'ink weight',
    ],
    to: '/print/specs',
  },
  {
    id: 5,
    title: 'Online (overview)',
    body: `jewishnews.net.au
The premier online destination for Australian Jewish World News
jewishnews.net.au is the most comprehensive online news source for Australia’s Jewish community.`,
    keywords: ['Online', 'website', 'web', 'digital'],
    to: '/online',
  },
  {
    id: 6,
    title: 'Online Rates & Sizes',
    body: `Online Pricing
Media	Size (width x height)	Price (per month)
100% SOV	50% SOV	33% SOV
Billboard (top banner)	970 X 250px (desktop) 
320 X 50px (mobile)	$2200	$1600	$1000
Leaderboard (mid banner)	728 X 90px (desktop/tablet) 
320 X 50px (mobile)	$1800	$1200	$800
Medium rectangle (sidebar)	300 X 250px	$1200	$700	$500
Medium rectangle (in-article)	300 X 250px	$1250	$750	$550
Half page (sidebar)	300 X 600px	$1590	$945	$730
Half page (on articles)	300 X 600px	$1590	$945	$730
All prices subject to 10% GST.

SOV = Share Of Voice (the percentage of the total ad inventory for the position that will be allotted).

Specs & info
A click-through URL must be supplied for all online ads
Web display ad creatives may be suppled as JPG, PNG, GIF, HTML5 (zip, with ClickTAG)
Max file-size: 150Kb
All sizes must be supplied for the top banner (leaderboard) to account for tablets and smartphones.
Other sizes & positions may be available on request
Geo-targeting (state level) is available on request`,
    keywords: [
      'Online sizes',
      'online specs',
      'digital specs',
      'digital sizes',
      'digital',
      'online rates',
      'rates',
      'sizes',
      'specs',
      'MREC',
      'leaderboard',
      'billboard',
      'banner',
      'price',
    ],
    to: '/online/rates-sizes',
  },
  {
    id: 7,
    title: 'iPad, iPhone & android app',
    body: `The AJN app features direct replication of our print editions and supplements, plus live article feed so our readers are always up to date.
Enhance your print advertisement with video or webpage links

All valid, readable text URLs will be automatically converted to links in our apps.`,
    keywords: [
      'android',
      'ios',
      'tablet',
      'smartphone',
      'app',
      'google play',
      'itunes',
      'print replica',
    ],
    to: '/app',
  },
  {
    id: 8,
    title: 'online ePaper',
    body: `The AJN online ePaper features direct replication of our print editions and supplements, plus live article ticker so our readers are always up to date.
Enhance your print advertisement with video or webpage links

All valid, readable text URLs will be automatically converted to links in our apps.`,
    keywords: ['epaper', 'browser', 'print replica'],
    to: '/epaper',
  },
  {
    id: 9,
    title: 'Upload artwork',
    keywords: ['upload', 'file', 'form', 'upload art', 'creative', 'send'],
    to: '/upload',
  },
  {
    id: 10,
    title: 'Download Media Kit',
    keywords: ['media kit', 'pdf', 'specs', 'sizing'],
    href: `${mediaKit || config.mediaKitFallback}`,
  },
  {
    id: 11,
    title: 'Download colour settings file',
    keywords: ['Color Settings', 'csf'],
    href: config.csfLink,
  },
  {
    id: 12,
    title: 'Subscribe',
    keywords: ['Subscriptions', 'subscribe'],
    href: config.subscribeLink,
  },
  {
    id: 13,
    title: 'Property Weekly',
    keywords: ['Property weekly', 'prw', 'real estate', 'property'],
    href: config.propertyLink,
  },
  {
    id: 14,
    title: 'Contact',
    keywords: ['office', 'contact', 'number', 'email', 'address', 'phone', 'sydney', 'melbourne'],
    info: [
      'Melbourne office:\n Level 1, 589 Glen Huntley Rd, Elsternwick VIC 3185\n Phone: +61 3 8531 8900 \n Email: ajn@jewishnews.net.au',
      'Sydney office:\n Level 1, 55 Grafton Street, Bondi Junction NSW 2022 \n Telephone: +61 2 8383 0800\n Email: ajn@jewishnews.net.au',
    ],
  },
  {
    id: 15,
    title: 'Social notices',
    info: [
      'Social notices (births, deaths, marriages etc.): \n notices@jewishnews.net.au\n 1800 010 171',
    ],
    keywords: ['notices', 'births', 'deaths', 'marriages', 'bereavements', 'eulogy'],
  },
  {
    id: 16,
    title: 'Print Distribution',
    keywords: [
      'print distribution', 'distribution', 'newsagents', 'suburbs', 'map', 'delivery', 'post', 'shops', 'retailers', 'stores', 'stockists', 'availability'
    ],
    body: `Print distribution
THREE METHODS OF DISTRIBUTION
Newsagent deliveries

Newsagent and retail direct sales

Australia Post

NEWSAGENTS
The Australian Jewish News is stocked and delivered by 42 newsagents in the following areas:

Sydney
Atarmon, Balmain, Bellevue Hill, Bondi, Bondi Junction, Bronte Waverley, Cammeray, Chatswood, Clovelly, Coogee, Cremorne, Crows Nest, Darlinghurst, Double Bay, Eastgardens, Edgecliff, Gordon, Hurstville, Kensington, Killara, Kings Cross, Kingsford, Lane Cove, Lindfield, Manly, Maroubra, Marrickville, Matraville, Mosman, Neutral Bay, North Sydney, Northbridge, Paddington, Pymble, Randwick, Rose Bay, Roseville, St Ives, St Leonards, Sydney City, Vaucluse, Woollahara.

Melbourne
Armadale, Ashburton, Auburn, Balaclava, Balwyn, Balwyn North, Bentleigh, Bentleigh East, Blackburn South, Box Hill South, Brighton, Bulleen, Camberwell, Canterbury, Carnegie, Caulfield, Caulfield North, Caulfield South, Chadstone, Clayton, Collingwood, Elsternwick, Elwood, Fitzroy North, Gardenvale, Hampton, Hawthorn, Highett, Kew, Lower Templestowe, Malvern, Melbourne City, Middle Brighton, Moorabbin, Mt Waverly, Murrumbeena, North Carlton, North Melbourne, Northcote, Notting Hill, Ripponlea, Riversdale, Sandringham, South Box Hill, South Melbourne, Springvale, St Kilda, St Kilda East and Toorak.

RETAIL OUTLETS
Available at Coles Supermarkets, Woolworths Supermarkets, Independent Supermarkets, 7Eleven Convenience Stores and a number of corner stores.

AUSTRALIA POST
Also, The Australian Jewish News is delivered by post throughout the country and internationally.`,
    to: 'print/distribution',
  },
  {
    id: 17,
    to: 'branding',
    title: 'AJN branding assets & guidelines',
    keywords: ['branding', 'logo', 'ajn logo', 'assets', 'logo files', 'Australian Jewish News logo']
  }
];

export default docs;
