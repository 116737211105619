import React from 'react';
import styled, { css } from 'styled-components';

const I = styled.span`
  line-height: inherit !important;
  font-size: inherit !important;
  vertical-align: middle;
  margin-right: ${(props) => props.left && css`0.5rem;`};
  margin-left: ${(props) => props.right && css`0.5rem;`};
`;
export const Icon = ({ children, left, right }) => (
  <I left={left} right={right} className="material-icons">
    {children}
  </I>
);

export const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const Label = styled.span`
  font-size: 0.935rem;
  margin-bottom: 1rem;
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.light};
  a {
    color: ${(props) =>
      props.color ? props.theme[props.color] : props.theme.light};
    border: none;
  }
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme[props.backgroundColor]
      : props.theme.blue};
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  transition: background-color 75ms ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.theme[props.hoverColor] : props.theme.primary};
  }
`;

export const Ul = styled.ul`
  font-size: 1.15rem;
  line-height: 1.6;
  li {
    margin-bottom: 0.75rem;
  }
`;
export const UnorderedList = styled.ul`
  font-size: 1.2rem;
  list-style: none;
  padding: 0;
  /* margin-bottom: 4rem; */
  li {
    margin-bottom: 0.75rem;
    display: flex;
    line-height: 1.6;
    .material-icons {
      margin-right: 1rem;
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
  th,
  td {
    padding: 1rem 2rem 1rem 0;
    text-align: left;
    border-bottom: 1px solid ${(props) => `${props.theme.dark}29`};
  }
`;

export const Para = styled.p`
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  @media screen and (max-width: 768px) {
    font-size: 1.08rem;
    line-height: 1.5;
  }
`;

export const Text = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.05rem;
  line-height: 1.65;
`;

export const Alert = styled.p`
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.05rem;
  color: ${(props) => props.theme.red};
  background-color: ${(props) => props.theme.light};
  padding: 0.75rem;
`;

export const Heading = styled.h1`
  @media print {
    color: ${(props) => props.theme.primary};
  }
  background-image: ${(props) =>
    props.light
      ? 'linear-gradient(to right, rgb(180, 205, 255), #90f7fe)'
      : 'linear-gradient(to right,rgb(37, 89, 193),#0c2944)'}; 
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  /* color: ${(props) => `${props.theme.dark}8c`}; */
  font-size: 4rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1024px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 468px) {
    font-size: 1.5rem;
  }
`;

export const SubHeading = styled.h2`
  font-size: 1.65rem;
  line-height: 1.43;
  @media screen and (max-width: 768px) {
    font-size: 1.35rem;
    line-height: 1.35;
  }
  @media screen and (max-width: 468px) {
    font-size: 1.15rem;
  }
`;

export const Head = styled(Heading)`
  font-size: 3.5rem;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  background-image: linear-gradient(to right, rgb(213, 27, 50), #ffada7);
  color: transparent;
  margin-bottom: 1rem;
  @media screen and (max-width: 1024px) {
    font-size: 2.8rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 468px) {
    font-size: 2rem;
  }
`;
export const Sub = styled(SubHeading)`
  font-weight: normal;
  font-size: 1.8rem;
  color: ${(props) => props.theme.primary};
  margin-top: 0;
`;

export const FlexLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;
export const Button = styled.button`
  padding: 0.65rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.theme[props.borderColor] : props.theme.light};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme[props.backgroundColor]
      : props.theme.light};
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme.dark};
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 1.5rem;
  &:hover {
    border: 1px solid
      ${(props) => (props.color ? props.theme[props.color] : props.theme.dark)};
  }
  a {
    text-decoration: none;
    border: none;
    color: inherit;
  }
`;

export const ButtonPrimary = styled(Button)`
  border: 1px solid ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.primary};
  color: white;
`;
export const ButtonSecondary = styled(Button)`
  border: 1px solid ${(props) => props.theme.secondary};
  background-color: ${(props) => props.theme.secondary};
  color: white;
`;
export const ButtonDark = styled(Button)`
  border: 1px solid ${(props) => props.theme.dark};
  background-color: ${(props) => props.theme.dark};
  color: white;
`;
export const ButtonOutline = styled(Button)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.dark};
  &:hover {
    border: 1px solid ${(props) => props.theme.dark};
    background-color: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.light};
  }
`;
export const ButtonRounded = styled.button`
  font-size: 1.45rem;
  padding: 1rem;
  border: none;
  background-color: ${(props) => props.theme.dark};
  color: white;
  cursor: pointer;
  z-index: 2;
  border-radius: 100%;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 0.75rem;
  }
`;
export const Container = styled.section`
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    /* display: block; */
  }
`;
const darkColors = ['dark', 'blue', 'primary'];

export const Panel = styled.div`
  @media print {
    color: #000;
  }
  color: ${(props) =>
    props.color && darkColors.includes(props.color)
      ? props.theme.light
      : props.theme.dark};
  a {
    color: ${(props) =>
      props.color && darkColors.includes(props.color)
        ? props.theme.light
        : null};
  }
  button > a {
    color: inherit;
  }
  /* breaks sticky */
  /* overflow: hidden; */
  position: relative;
  background-color: ${(props) =>
    props.color
      ? Object.keys(props.theme).includes(props.color)
        ? props.theme[props.color]
        : props.color
      : 'white'};
  padding: 4rem;
  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
`;
export const Rel = styled.div`
  position: relative;
`;
export const Section = styled.section`
  margin-bottom: 2rem;
`;
export const Cover = styled.div`
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-color: ${(props) =>
    props.color ? props.theme[props.color] : null};
  width: 100%;
  height: 100%;
  min-height: 400px;
`;

export const HideOnMobile = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const HideOnDesktop = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
export const Sticky = styled.div`
  position: sticky;
  top: 0;
  background-color: ${(props) =>
    props.color ? props.theme[props.color] : '#ffffff'};
  overflow: auto;
  z-index: ${(props) => props.zIndex || 0};
  max-width: 100vw;
  @media screen and (max-width: 768px) {
    border-bottom: 1px solid #d6dde3;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  background: white;
  > * {
    min-width: max-content;
    padding-right: 2rem;
  }
`;
export const RichContent = styled.div`
  h3 {
    font-size: 1.35rem;
  }
  p {
    white-space: pre-wrap;

    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.15rem;
    @media screen and (max-width: 768px) {
      font-size: 1.08rem;
      line-height: 1.5;
    }
  }
  ul {
    p {
      margin: 0;
    }
  }
  hr {
    border-style: solid;
    border-color: ${(props) => props.theme.light};
    border-bottom: none;
  }
  &.html-small {
    p {
      font-size: 100%;
      margin-bottom: 0;
    }
  }
  /* this is for the html on the Income section of Readership */
  &.html-inline {
    h3 {
      display: inline;
      font-size: 1.8rem;
      margin-right: 0.4rem;
      line-height: 1;
    }
    p {
      display: inline;
      line-height: 1.8;
    }
  }
`;
