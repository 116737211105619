import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export const QuickLinks = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        quickLinks: contentfulIconList(title: { eq: "Quick Links" }) {
          title
          listItem {
            title
            text {
              text
            }
            icon
          }
        }
      }
    `}
  >
    {({ quickLinks }) => children(quickLinks)}
  </StaticQuery>
);
