import React, { Component } from 'react';
import { Config } from '../../queries';
export const { Provider, Consumer } = React.createContext({
  mediaKit: '',
});

class MediaKitProvider extends Component {
  state = {
    mediaKit: '',
  };
  componentDidMount() {

    fetch(this.props.graphqlEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `query {
          pdfByPublication(publication:"Media Kit" ) {
            id
            thumbUrl
            publication
            fileUrl
            shortLink
            datePublished
          } 
        }`,
      }),
    })
      .then(res => res.json())
      .then(
        res =>
          res.data &&
          this.setState({
            mediaKit: {
              pdfsShortLinkUrl: this.props.pdfsShortLinkUrl,
              ...res.data.pdfByPublication,
              realShortLink: this.props.pdfsShortLinkUrl + res.data.pdfByPublication.shortLink,
            },
          }),
      )
      .catch(error => console.log(error));
  }

  render() {
    return <Provider value={this.state.mediaKit}>{this.props.children}</Provider>;
  }
}

const MediaKit = ({ children }) => (
  <Config>
    {config => {
      return (
        <MediaKitProvider
          graphqlEndpoint={config.data[0].graphqlEndpoint}
          pdfsShortLinkUrl={config.data[0].pdfsShortLinkUrl}
          children={children}
        />
      );
    }}
  </Config>
);
export default MediaKit;

export const withMediaKit = C => {
  const WrappedComponent = props => {
    return (
      <Consumer>
        {mediaKit => {
          return <C {...props} mediaKit={mediaKit} />;
        }}
      </Consumer>
    );
  };
  return WrappedComponent;
};
