import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export const ContactInfo = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        phone: contentfulListItem(title: { eq: "contact-phone" }) {
          text {
            text
          }
          icon
        }
        email: contentfulListItem(title: { eq: "contact-email" }) {
          text {
            text
          }
          icon
        }
      }
    `}
  >
    {({ phone, email }) => children(phone, email)}
  </StaticQuery>
);
