import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

import { Helmet } from 'react-helmet';

import Search from '../Search';
import ogImage from '../../images/cover-collage-transparency.png';

import MediaKit from '../MediaKit';
import { Alert } from '../../elements';


const theme = {
  primary: '#0095da',
  secondary: '#29D9A6',
  dark: '#0d2a44',
  darker: '#051c32',
  light: '#f8f8f8',
  blue: '#2559c1',
  bluegrey: '#d6dde3',
  red: '#d51b32',
  white: '#ffffff',
};

// const AsyncHero = Loadable({
//   loader: () => import(/* webpackChunkName: "Home" */ './routes/Hero'),
//   loading: Loading,
// });
// const AsyncOverview = Loadable({
//   loader: () => import(/* webpackChunkName: "Overview" */ './routes/Overview'),
//   loading: Loading,
// });
// const AsyncReadership = Loadable({
//   loader: () => import(/* webpackChunkName: "Readership" */ './routes/Readership'),
//   loading: Loading,
// });
// const AsyncPrint = Loadable({
//   loader: () => import(/* webpackChunkName: "Print" */ './routes/Print'),
//   loading: Loading,
// });
// const AsyncPrintRates = Loadable({
//   loader: () => import(/* webpackChunkName: "Print-Rates" */ './routes/Print/PrintRates'),
//   loading: Loading,
// });
// const AsyncPrintSpecs = Loadable({
//   loader: () => import(/* webpackChunkName: "Print-Specs" */ './routes/Print/PrintSpecs'),
//   loading: Loading,
// });
// const AsyncOnline = Loadable({
//   loader: () => import(/* webpackChunkName: "Online" */ './routes/Online'),
//   loading: Loading,
// });
// const AsyncOnlineRates = Loadable({
//   loader: () => import(/* webpackChunkName: "online-Rates" */ './routes/Online/OnlineRates'),
//   loading: Loading,
// });
// const AsyncAjnApp = Loadable({
//   loader: () => import(/* webpackChunkName: "Ajn-App" */ './routes/AjnApp'),
//   loading: Loading,
// });
// const AsyncEpaper = Loadable({
//   loader: () => import(/* webpackChunkName: "Epaper" */ './routes/Epaper'),
//   loading: Loading,
// });
// const AsyncUpload = Loadable({
//   loader: () => import(/* webpackChunkName: "Upload" */ './routes/Upload'),
//   loading: Loading,
// });
// const AsyncNoRoute = Loadable({
//   loader: () => import(/* webpackChunkName: "No-Route" */ './routes/NoRoute'),
//   loading: Loading,
// });

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  ::selection {
    background-color: ${theme.bluegrey};
    /* color: ${theme.dark}; */
  }
  ::-moz-selection {
    background-color: ${theme.blue};
    color: white;
  }
  a:focus, button:focus {
    outline: 3px solid ${theme.primary};
    outline-offset: 1px;
  }
  html {
    /* safari why you broken?  */
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
  }
  a, .text-link {
    font-size: inherit;
    font-family: inherit;
    padding: 0;
    text-decoration: none;
    border-bottom: 1px solid;
    border-right: none;
    border-left: none;
    border-top: none;
    background: inherit;
    &.no-border{
      border: none;
    }
    color: ${theme.blue};
    cursor: pointer;
  }
  p {
    line-height: 1.56;
    font-size: 1.2rem;
    @media screen and (max-width: 768px) {
      font-size: 1.15rem;
      line-height: 1.5;
    }
  }
  li {
    @media screen and (max-width: 768px) {
      font-size: 1.05rem;
      line-height: 1.5;
    }
  }
  h1, h2 {
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* font-weight: normal; */
  }
  .gatsby-image-wrapper {

  }
  .svg_white{fill: #ffffff;}
  .svg_blue{fill: #3062ff;}
    .svg_highlight{fill: #3062ff;}

  .svg_beige{fill: #C7B7B1;}
  .svg_darkbeige{fill:#a48b7f;}
  .svg_grey{fill: #E5E5E5;}
  .svg_red{fill: #bf427e;}
  .svg_light{fill:#F8F8F8;}
  .ad-test {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .close-alert {
    background-color: rgba(255,255,255,0.3);
    padding: 4px;
    cursor: pointer;
    margin-left: 1rem;
  }
  .alert-text {
    padding: 0.75rem;
  }
`;

const Body = styled.div`
  background-color: ${props => props.theme.light};
`;

const AdAlert = styled(Alert)`
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: ${(props) => props.theme.red};
  color: ${(props) => props.theme.light};
`;

const Layout = ({ children }) => {
  return (
    <MediaKit>
      <React.Fragment>
        <Helmet titleTemplate="%s | AJN advertising">
          <html lang="en" />
          <script innerHTML="console.log('<!-- Site by Ren Rizzolo | renrizzolo.com -->')" />
          <meta charSet="utf-8" />
          <meta property="og:image:width" content="1080" />
          <meta property="og:image:height" content="565" />
          <meta property="og:title" content="Advertise with The AJN" />
          <meta
            name="description"
            content="Pricing, specs and sizes across Print and Digital advertising"
          />
          <meta
            property="og:description"
            content="Pricing, specs and sizes across Print and Digital advertising"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@aus_jewishnews" />
          <meta name="twitter:title" content="The Australian Jewish News advertising" />
          <meta
            name="twitter:description"
            content="advertise on The AJN's print & digital properties"
          />
          <meta name="twitter:image" content={ogImage} />
          <meta property="og:url" content="https://advertise.jewishnews.net.au" />
          <meta property="og:image" content={ogImage} />
          <link rel="preconnect" href="https://pdfs.jewishnews.net.au" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Material+Icons&family=Work+Sans:wght@300;600&display=swap" rel="stylesheet" />

          <script>
            {`
            // this might not be needed now
            // the resources were moved to the 
            // the netlify ajn-mediakit domain 
            // (a certain word in a resource's domain name
            // triggers a certain extension)
              const alertBox = document.getElementsByClassName('alert-box')[0];
              const el = document.getElementsByClassName('adBanner')[0];
              const closeAlert = function() {
                  const span = document.getElementsByClassName('close-alert')[0];
                  if (span && alertBox) {
                    alertBox.style.display = 'none';
                  }
                }
              if (el) {
                const rect = el.getBoundingClientRect();
                if(rect && rect.height === 0) {
                  document.getElementsByClassName('alert-box')[0].innerHTML = '<div class="alert-text">Your <strong>ad blocker</strong> may be messing with the display of this website! Please whitelist this site - no ads here! <span class="close-alert" onClick="closeAlert()">close</span></div>'
                }
              }
            `}
          </script>
        </Helmet>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <AdAlert className="alert-box"></AdAlert>
          <Body>
            {children}
            <Search />
          </Body>
        </ThemeProvider>
      </React.Fragment>
    </MediaKit>
  );
}

export default Layout;
