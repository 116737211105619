// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-app-index-js": () => import("./../../../src/templates/app/index.js" /* webpackChunkName: "component---src-templates-app-index-js" */),
  "component---src-templates-branding-index-js": () => import("./../../../src/templates/branding/index.js" /* webpackChunkName: "component---src-templates-branding-index-js" */),
  "component---src-templates-epaper-index-js": () => import("./../../../src/templates/epaper/index.js" /* webpackChunkName: "component---src-templates-epaper-index-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-online-index-js": () => import("./../../../src/templates/online/index.js" /* webpackChunkName: "component---src-templates-online-index-js" */),
  "component---src-templates-online-rates-sizes-index-js": () => import("./../../../src/templates/online/rates-sizes/index.js" /* webpackChunkName: "component---src-templates-online-rates-sizes-index-js" */),
  "component---src-templates-overview-index-js": () => import("./../../../src/templates/overview/index.js" /* webpackChunkName: "component---src-templates-overview-index-js" */),
  "component---src-templates-print-distribution-index-js": () => import("./../../../src/templates/print/distribution/index.js" /* webpackChunkName: "component---src-templates-print-distribution-index-js" */),
  "component---src-templates-print-index-js": () => import("./../../../src/templates/print/index.js" /* webpackChunkName: "component---src-templates-print-index-js" */),
  "component---src-templates-print-rates-sizes-index-js": () => import("./../../../src/templates/print/rates-sizes/index.js" /* webpackChunkName: "component---src-templates-print-rates-sizes-index-js" */),
  "component---src-templates-print-specs-index-js": () => import("./../../../src/templates/print/specs/index.js" /* webpackChunkName: "component---src-templates-print-specs-index-js" */),
  "component---src-templates-readership-index-js": () => import("./../../../src/templates/readership/index.js" /* webpackChunkName: "component---src-templates-readership-index-js" */),
  "component---src-templates-terms-and-conditions-index-js": () => import("./../../../src/templates/terms-and-conditions/index.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-index-js" */),
  "component---src-templates-upload-index-js": () => import("./../../../src/templates/upload/index.js" /* webpackChunkName: "component---src-templates-upload-index-js" */)
}

