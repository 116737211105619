import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export default class ScrollableLink extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['link', 'navLink']),
    element: PropTypes.string,
    // maxWidth: PropTypes.number,
  };
  static defaultProps = {
    type: 'link',
    element: 'content',
    // maxWidth: 540
  };
  scrollTo = () => {
    // const { element } = this.props;
    // const el = document.getElementById(element);

    // if (window.innerWidth < maxWidth) {
    //   el && el.scrollIntoView();
    // }
    window.scrollTo(0, 0);
  };

  render() {
    const { element, type, ...rest } = this.props;

    return type === 'link' ? (
      <Link {...rest} onClick={this.scrollTo} />
    ) : (
      <Link {...rest} onClick={this.scrollTo} />
    );
  }
}
