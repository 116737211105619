import React, { Component } from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';
import { Trail, animated } from 'react-spring';
import docs from './docs';
import lunr from 'lunr';
import ScrollableLink from '../ScrollableLink';
import { Icon, SubHeading } from '../../elements';

const SearchContainer = styled.div`
  width: 100%;
  max-width: 50%;
  right: 0;
  height: 50%;
  @media screen and (max-height: 500px) {
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    height: 100%;
  }
  padding: 2rem;
  position: fixed;
  bottom: 0;
  background-color: white;
  z-index: 1;
  color: ${props => props.theme.dark};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  ul {
    margin: 0;
    padding: 0;
    max-height: 40%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      flex-wrap: nowrap;
    }
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    li {
      font-size: 1.25rem;
      list-style: none;
      margin-bottom: 1rem;
      a:hover {
        color: ${props => props.theme.dark};
      }
    }
  }
`;

const SearchInput = styled.input`
  margin-bottom: 2rem;
  width: 100%;
  background: transparent;
  font-size: 1.65rem;
  padding-bottom: 0.5rem;
  border: none;
  outline: none;
  &:focus {
    border-bottom-color: ${props => props.theme.blue};
  }
  &::placeholder {
    color: ${props => props.theme.bluegrey};
  }
  border-bottom: 1px solid ${props => props.theme.primary};
`;
const PreLine = styled.p`
  white-space: pre-line;
`;

const Label = styled.label`
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: block;
  font-weight: bold;
  color: ${props => props.theme.red};
`;

class SearchBar extends Component {
  state = {
    value: '',
    results: [],
  };

  componentDidMount() {
    this.createSearchIndex();

    this.docs = docs(this.props.mediaKit, this.props.config);
    window.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }
  createSearchIndex = () => {
    const docs1 = docs(this.props.mediaKit, this.props.config);
    this.searchIndex = lunr(function() {
      this.ref('id');
      this.field('title', { boost: 10 });
      this.field('body');
      this.field('keywords', { boost: 5 });

      docs1.forEach(doc => {
        this.add(doc);
      });
    }, this);
  };

  handleKeyPress = ({ key }) => {
    if (key === 'Escape') {
      this.props.showSearchBar();
    }
  };

  onChange = ({ target: { value } }) => {
    // Set captured value to input
    this.setState(() => ({ value }));

    // Search against lines and index if they exist
    if (this.searchIndex && value.length) {
      return this.setState(() => ({
        results: this.searchIndex.search(value.trim()),
      }));
    }
  };
  onSubmit = e => {
    e.preventDefault();
  };

  render() {
    const { style, showSearchBar } = this.props;
    const { value, results } = this.state;
    return (
      <Location>
        {({ location }) => (
          <SearchContainer role="search" style={style}>
            <form onSubmit={this.onSubmit}>
              <Label htmlFor="search-input">
                <SubHeading>Quick search by keyword</SubHeading>
              </Label>
              <SearchInput
                id="search-input"
                ariaRole="searchbox"
                autoFocus
                placeholder="Start typing"
                type="search"
                value={value}
                onChange={this.onChange}
              />
            </form>
            {results && (
              <Results
                searchDocs={this.docs}
                location={location}
                results={results}
                showSearchBar={showSearchBar}
              />
            )}
          </SearchContainer>
        )}
      </Location>
    );
  }
}

const Results = ({ results, location, showSearchBar, searchDocs }) => {
  return (
    <ul>
      {results.length > 0 && (
        <Trail
          native
          from={{ opacity: 0, transform: 'translateX(-25px)' }}
          to={{ opacity: 1, transform: 'translateX(0px)' }}
          leave={{ transform: 'translateX(25px)' }}
          keys={result => result.ref}
          items={results}
        >
          {result => styles => {
            const item = searchDocs[result.ref];
            return item && item.to !== location.pathname ? (
              <animated.li key={item.title} style={styles} onClick={showSearchBar}>
                {item.to || item.href ? (
                  item.to ? (
                    <ScrollableLink to={item.to}>{item.title}</ScrollableLink>
                  ) : (
                    <React.Fragment>
                      <a href={item.href} target="_blank">
                        {item.title}
                      </a>
                      &nbsp;
                      <small>
                        <Icon>open_in_new</Icon>
                      </small>
                    </React.Fragment>
                  )
                ) : (
                  item.info && item.info.map((line, i) => <PreLine key={i}>{line}</PreLine>)
                )}
              </animated.li>
            ) : null;
          }}
        </Trail>
      )}
    </ul>
  );
};

export default SearchBar;
