import React, { Component } from 'react';
import { Transition } from 'react-spring';
import styled from 'styled-components';
import Searcher from './Searcher';
import { Icon, ButtonRounded } from '../../elements';
import { withMediaKit } from '../MediaKit';
import { Config } from '../../queries';

const SearchButton = styled(ButtonRounded)`
  position: fixed;
  right: 0;
  bottom: 0;
`

class Search extends Component {
  state = {
    showSearch: false,
  };

  showSearchBar = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    });
  };

  render() {
    const { showSearch } = this.state;
    const label = showSearch ? 'Close search' : 'Open search';
    return (
      <div>
        <div className="adBanner ad-test" />
        <SearchButton
          tabIndex={0}
          onClick={this.showSearchBar}
          title={label}
          aria-label={label}
        >
          <Icon>{showSearch ? 'close' : 'search'}</Icon>
        </SearchButton>
        {
          <Config>
            {(config) => (
              <Transition
                items={showSearch}
                from={{ opacity: 0, transform: 'translateY(  40px)' }}
                enter={{ opacity: 1, transform: 'translateY(0)' }}
                leave={{ opacity: 0 }}
              >
                {(show) =>
                  show &&
                  ((styles) => (
                    <Searcher
                      mediaKit={this.props.mediaKit.realShortLink}
                      config={config.data}
                      style={styles}
                      location={this.props.location}
                      showSearchBar={this.showSearchBar}
                    />
                  ))
                }
              </Transition>
            )}
          </Config>
        }
      </div>
    );
  }
}

export default withMediaKit(Search);
